











import { defineComponent } from '@vue/composition-api';
import TemplateListTable from '@/components/TemplateListTable.vue';
import TemplateCreateWizard from '@/components/TemplateCreateWizard/TemplateCreateWizardDialog.vue';
import Store from '@/store';

export default defineComponent({
  components: {
    TemplateListTable,
    TemplateCreateWizard,
  },

  setup() {
    /**
     * On templateCreated emmited reloads TemplateListstore data
     */
    const onTemplateCreated = () => {
      Store.dispatch('templateList/getUnpublishedTemplatesData');
      Store.dispatch('templateList/filter');
    };

    return { onTemplateCreated };
  },
});
